/* eslint-disable jsx-a11y/iframe-has-title */
import Banner from "../../sections/common/banner";
import { global } from "../../../globals/constants";
import { BsArrowRight } from "react-icons/bs";
import contactData from "../../../globals/data/contact";
import LocalForm from "../steps-form/local-form/local";
import Maps from "../../elements/Maps";

export default function ContactPage() {
  return (
    <>
      <Banner _data={global.banner + "1.jpg"} />

      <section className="mb-5">
        <div className="container">
          <div className="mt-5 mb-5">
            <LocalForm />
          </div>
          <div className="row">
            {contactData.map((data) => (
              <ContactItem
                key={data.id}
                heading={data.heading}
                text={data.text}
                icon={data.icon}
                item1={data.item1}
                link={data.link}
              />
            ))}
          </div>

          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="contact-map-wrap">
                <Maps />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function ContactItem({ icon, heading, text, item1, item2, link }) {
  return (
    <a className="col-lg-4 col-md-6 col-12" href={link} target="_blank">
      <div className="single-contact-card">
        <div className="top-part">
          <div className="icon">{icon}</div>
          <div className="title">
            <h4>{heading}</h4>
            <span>{text}</span>
          </div>
        </div>
        <div className="bottom-part">
          <div className="info">
            <p>{item1}</p>
            <p>{item2}</p>
          </div>
          <div className="icon">
            <BsArrowRight />
          </div>
        </div>
      </div>
    </a>
  );
}
