// import { StepFormItemOne } from "../StepsForm/StepForm";
import LocalForm from "../steps-form/local-form/local";
import classes from "./styles.module.scss";

const HeroForm = () => {
  return (
    <section className={classes.heroForm}>
      <LocalForm
        withoutStepper
        classNames={{
          stepOne: classes.step,
          wrapper: classes.wrapper,
          container: classes.container,
        }}
      />
    </section>
  );
};

export default HeroForm;
