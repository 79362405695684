import React from "react";

function Map() {
  return (
    <div id="map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.7799488485084!2d-88.25040622477201!3d41.76840197227658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880efa20384261d7%3A0xd673eeea929c2666!2zMjc1MCBQb250aWFjIExuLCBBdXJvcmEsIElMIDYwNTAyLCDQodCo0JA!5e0!3m2!1sru!2s!4v1728247162490!5m2!1sru!2s"
        frameBorder="0"
        style={{
          border: "0",
          width: "100%",
        }}
        height={400}
        allowFullScreen=""
        aria-hidden="false"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
