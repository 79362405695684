import SectionSlider from "../../sections/home/index/section-slider";
import SectionWhatWeDo from "../../sections/home/index/section-what-we-do";
import SectionWhyChooseUs from "../../sections/home/index/section-why-choose-us";
import SectionBooking1 from "../../sections/home/index/section-booking1";
import SectionServices from "../../sections/services/section-services";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import { data } from "../../../globals/data/home";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function HomePage() {
  useEffect(() => {
    loadScript("/assets/js/custom.js", true);
  });

  return (
    <>
      <SectionSlider />
      <SectionBooking1 _data={data.booking} />
      <SectionWhatWeDo _data={data.whatwedo} />
      <SectionWhyChooseUs _data={data.whychooseus} />
      <SectionServices _data={data.services} />
      <SectionCompanyApproch1 _data={data.approach} />
    </>
  );
}

export default HomePage;
