import { useLocation } from "react-router-dom";

import Header from "../app/sections/common/header";
import Footer from "../app/sections/common/footer";
import AppRoutes from "../routing/app-routes";

export default function RootLayout() {
  const currentpath = useLocation().pathname;
  return (
    <>
      <div className="page-wraper">
        {!currentpath?.startsWith("/error") && <Header />}

        {/* CONTENT START */}
        <div className="page-content">
          <AppRoutes />
        </div>
        {/* CONTENT END */}

        {!currentpath?.startsWith("/error") && <Footer />}

        {/* BUTTON TOP START */}
        <button className="scroltop">
          <span className="fa fa-angle-up  relative" id="btn-vibrate"></span>
        </button>
      </div>
    </>
  );
}
