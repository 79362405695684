import { global } from "../constants";
import { data as homeData } from "../data/home";

export const data = {
  allservices: homeData.services.services.map(({ title, id }) => ({
    title,
    id,
  })),

  posts: [
    {
      image: global.recentBlog + "pic1.jpg",
      date: "January 08, 2023",
      title: "Equipment you can count on. People you can trust.",
    },
    {
      image: global.recentBlog + "pic2.jpg",
      date: "January 12, 2023",
      title: "Advanced Service Functions by Air Transport",
    },
    {
      image: global.recentBlog + "pic3.jpg",
      date: "January 16, 2023",
      title: "Proper arrangement for keeping the goods in the warehouse",
    },
  ],

  gallery: [
    global.sideGallery + "pic1.jpg",
    global.sideGallery + "pic2.jpg",
    global.sideGallery + "pic3.jpg",
    global.sideGallery + "pic4.jpg",
    global.sideGallery + "pic5.jpg",
    global.sideGallery + "pic6.jpg",
  ],

  tags: [
    "Logistics",
    "Company ",
    "Business",
    "Cargo ",
    "Trucks",
    "Delivery vans",
    "Warehouse",
    "Ocean",
    "Transports",
  ],
};
