import { NavLink } from "react-router-dom";
import { PHONE_NUMBER, publicUrlFor } from "../../../globals/constants";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  return (
    <header
      className={
        "site-header header-style-3 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
    >
      <div className="sticky-header main-bar-wraper  navbar-expand-lg">
        <div className="main-bar">
          <div className="container-fluid clearfix">
            <div className="logo-header">
              <div className="logo-header-inner logo-header-one">
                <NavLink to="/">
                  <img
                    src={publicUrlFor("/assets/images/logo-dark.png")}
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>

            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              className="navbar-toggler collapsed"
              onClick={toggleNavClass}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first"></span>
              <span className="icon-bar icon-bar-two"></span>
              <span className="icon-bar icon-bar-three"></span>
            </button>

            {/* MAIN Nav */}
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
              <ul className="nav navbar-nav">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/services">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">FAQ</NavLink>
                </li>

                <li>
                  <NavLink to="/contact-us">Contact</NavLink>
                </li>
              </ul>
            </div>

            {/* Header Right Section */}
            <div className="extra-nav header-2-nav">
              <div className="extra-cell">
                <div className="header-nav-call-section">
                  <div className="detail">
                    <span className="title">Free Call In U.S.A</span>
                    <span>
                      <a target="_blank" href={`tel:${PHONE_NUMBER}`}>
                        {PHONE_NUMBER}
                      </a>
                    </span>
                  </div>
                  <div className="media">
                    <img
                      src={publicUrlFor("/assets/images/call.png")}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
