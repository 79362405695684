import { Link, NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { data } from "../../../globals/data/home";

export default function Footer() {
  return (
    <>
      <footer className="footer-dark">
        {/* NEWS LETTER SECTION START */}
        <div className="ftr-nw-ltr site-bg-white">
          <div className="ftr-nw-ltr-inner site-bg-primary">
            <div className="container">
              <div className="ftr-nw-img">
                <img
                  src={publicUrlFor("/assets/images/news-l-bg.png")}
                  alt="#"
                />
              </div>
              <div className="ftr-nw-content w-100 ">
                <div className="ftr-nw-form w-100">
                  <Link to="/contact-us">
                    <button className="ftr-nw-subcribe-btn w-100">
                      Quote Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NEWS LETTER SECTION END */}

        <div
          className="ftr-bg"
          style={{
            backgroundImage: `url(${publicUrlFor("/assets/images/f-bg.jpg")})`,
          }}
        >
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/">
                        <img
                          src={publicUrlFor("/assets/images/logo-dark.png")}
                          alt="#"
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <NavLink to="/about-us">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/services1">Services</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blogs/grid">Blog</NavLink>
                      </li>
                      <li>
                        <NavLink to="/faq">FAQ</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Services</h3>
                    <ul>
                      {data.services.services.map((service) => (
                        <li>
                          <NavLink to={`/services/${service.id}`}>
                            {service.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Community</h3>
                    <ul>
                      <li>
                        <NavLink to="/privacy">Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/terms">Terms & Condition</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}

          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    Copyright © 2024 by RS Ship.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
