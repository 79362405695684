import {
  ADDRESS,
  ADDRESS_LINK,
  EMAIL,
  PHONE_NUMBER,
  publicUrlFor,
} from "../../../globals/constants";

export default function SectionSideContact() {
  return (
    <>
      <div className="widget tw-contact-bg-section">
        <h4 className="section-head-small mb-4">Any Emergency?</h4>
        <div
          className="tw-contact-bg-inner"
          style={{
            backgroundImage: `url(${publicUrlFor(
              "/assets/images/background/bg-4.jpg"
            )})`,
          }}
        >
          <div className="section-top">
            <span>Call Our 24/7 Customer Support</span>
            <h3 className="tw-con-number">
              <a href={`tel:${PHONE_NUMBER}`}> {PHONE_NUMBER}</a>
            </h3>
          </div>
          <div className="section-bot">
            <ul>
              <li>
                <span>
                  <img
                    src={publicUrlFor("/assets/images/icons/map-marker.png")}
                    alt="#"
                  />
                </span>
                <a href={ADDRESS_LINK} target="_blank">
                  {ADDRESS}
                </a>
              </li>
              <li>
                <span>
                  <img
                    src={publicUrlFor("/assets/images/icons/map-marker.png")}
                    alt="#"
                  />
                </span>
                <p>{EMAIL}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
