import { useParams } from "react-router-dom";
import SectionBookService from "./section-book-service";
import SectionServiceContent from "./section-service-content";
import { data } from "../../../globals/data/home";

export default function SectionServiceDetail() {
  const { id } = useParams();
  return (
    <>
      <div className="service-full-info">
        <div className="services-etc m-b30">
          <SectionServiceContent
            _data={data.services.services.find((service) => service.id === id)}
          />
          {/* <SectionBookService /> */}
        </div>
      </div>
    </>
  );
}
