import { publicUrlFor } from "../../../globals/constants";
import Error404Page from "../../components/misc/error404";

export default function SectionServiceContent({ _data }) {
  if (!_data) {
    return <Error404Page />;
  }

  return (
    <>
      <div className="service-category-title">
        <div className="service-category-media">
          <img src={publicUrlFor("/assets/images/icons/pic7.png")} alt="#" />
        </div>
        <h2>{_data.title}</h2>
      </div>
      <div className="wt-media">
        <img src={_data.bgImage} alt="#" />
      </div>
      {_data.details.content.map((detail) => (
        <>
          <h2 className="wt-title mt-4 mb-4">{detail.heading}</h2>
          {/* <div> */}
          {detail?.texts?.map((text) => (
            <p>{text}</p>
          ))}
          {/* </div> */}
        </>
      ))}
    </>
  );
}
