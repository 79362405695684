import Banner from "../../sections/common/banner";
import SectionWhatWeDo from "../../sections/home/index/section-what-we-do";
import SectionAchivements1 from "../../sections/home/index2/section-achivements";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import { data } from "../../../globals/data/about-us";

export default function AboutUsPage() {
  return (
    <>
      <Banner _data={data.banner} />
      <SectionWhatWeDo _data={data.whatwedo} />
      <SectionAchivements1 _data={data.achivements} />
      <SectionCompanyApproch1 _data={data.approach} />
    </>
  );
}
