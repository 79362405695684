import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import loc from "antd/locale/en_US";
import { primaryColor } from "./globals/defaultStyles";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <ConfigProvider
          locale={loc}
          form={{
            scrollToFirstError: true,
            requiredMark: false,
          }}
          theme={{
            token: {
              colorPrimary: primaryColor,
              borderRadius: 0,
              fontSize: 15,
              fontFamily: "Oswald",
            },
            components: {
              Select: {
                controlHeight: 40,
                fontSizeIcon: 18,
              },
              Input: {
                controlHeight: 40,
                fontSizeIcon: 18,
              },
              DatePicker: {
                controlHeight: 40,
                fontSizeIcon: 18,
              },
              Form: {
                labelFontSize: 18,
                fontWeightStrong: 500,
              },
              Button: {
                fontSize: 18,
                controlHeight: 40,
              },
              Space: {
                controlOutlineWidth: "100%",
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
